<template>
  <TheLoadScreen v-if="!dataLoaded"/>
  <TheHeader />
  <main>
    <BlockFirstScreen />
    <BlockBenefits />
    <BlockPortfolio />
    <BlockAfterBefore />
    <BlockProcess />
    <BlockTeam />
    <BlockAcquaintance />
    <BlockCompany />
    <BlockEducation />
    <BlockTariff />
    <BlockTrends />
    <BlockMedia />
    <BlockContacts />
    <TheFooter />
    <TheModal />
    <TheModalFrame />
  </main>
</template>

<script>
import TheLoadScreen from '@/components/TheLoadScreen/TheLoadScreen.vue';
import TheHeader from '@/components/TheHeader/TheHeader.vue';

import BlockFirstScreen from '@/components/BlockFirstScreen/BlockFirstScreen.vue';
import BlockBenefits from '@/components/BlockBenefits/BlockBenefits.vue';
import BlockPortfolio from '@/components/BlockPortfolio/BlockPortfolio.vue';
import BlockAfterBefore from '@/components/BlockAfterBefore/BlockAfterBefore.vue';
import BlockProcess from '@/components/BlockProcess/BlockProcess.vue';
import BlockTeam from '@/components/BlockTeam/BlockTeam.vue';
import BlockAcquaintance from '@/components/BlockAcquaintance/BlockAcquaintance.vue';
import BlockCompany from '@/components/BlockCompany/BlockCompany.vue';
import BlockEducation from '@/components/BlockEducation/BlockEducation.vue';
import BlockTariff from '@/components/BlockTariff/BlockTariff.vue';
import BlockTrends from '@/components/BlockTrends/BlockTrends.vue';
import BlockMedia from '@/components/BlockMedia/BlockMedia.vue';
import BlockContacts from '@/components/BlockContacts/BlockContacts.vue';

import TheFooter from '@/components/TheFooter/TheFooter.vue';
import TheModal from '@/components/TheModal/TheModal.vue';
import TheModalFrame from '@/components/TheModalFrame/TheModalFrame.vue';

export default {
  name: 'App',
  components: {
    TheLoadScreen,
    TheHeader,
    BlockFirstScreen,
    BlockBenefits,
    BlockPortfolio,
    BlockAfterBefore,
    BlockProcess,
    BlockTeam,
    BlockAcquaintance,
    BlockCompany,
    BlockEducation,
    BlockTariff,
    BlockTrends,
    BlockMedia,
    BlockContacts,
    TheFooter,
    TheModal,
    TheModalFrame,
  },
  computed: {
    dataLoaded() {
      return this.$store.state.data.dataLoaded;
    },
  },
  created() {
    this.$store.dispatch('init', {
      route: 'home-v2',
    });
  },
  mounted() {
    this.dispatchWindowWidth();
    window.addEventListener('resize', this.dispatchWindowWidth);
  },
  methods: {
    dispatchWindowWidth() {
      this.$store.dispatch('changeWidth', window.innerWidth);
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.dispatchWindowWidth);
  },
};
</script>
